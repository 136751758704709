import { useQuery, useMutation, useQueryClient } from 'hooks'
import { useState } from 'react'
import { ProviderLeadContextType } from './ProviderLeadProvider'
import {
  putProviderLead,
  postProviderLeadSnapshot,
  fetchProviderLead,
  deleteSnapshot,
  fetchProviderLeadExpenseCalculate,
  putProviderLeadSnapshot
} from './services'
import { useToast } from 'components/Toast'

export const useProviderLead = (
  providerLeadId: string,
  snapshotId?: string
): ProviderLeadContextType => {
  const [calculatePayload, setCalculatePayload] = useState(undefined)

  const toast = useToast()
  const queryClient = useQueryClient()
  const defaultCallback = {
    onSuccess: () => {
      queryClient.refetchQueries(['providerLead', providerLeadId])
    },
    onError: () => {
      toast.error()
    }
  }
  const { data: providerLead, isLoading } = useQuery(['providerLead', providerLeadId], () =>
    fetchProviderLead(providerLeadId)
  )

  const saveSnapshot = useMutation(
    payload => postProviderLeadSnapshot({ providerLeadId, payload }),
    defaultCallback
  )

  const updateSnapshot = useMutation(
    payload => putProviderLeadSnapshot(payload as any),
    defaultCallback
  )

  const saveProviderLead = useMutation(
    payload => putProviderLead({ providerLeadId, payload }),
    defaultCallback
  )

  const { data: calculateExpense, isFetching: isCalculatingExpense } = useQuery(
    ['providerLeadExpense', providerLeadId, calculatePayload, 'valuation'],
    () => {
      return fetchProviderLeadExpenseCalculate({
        payload: { ...calculatePayload, calculation_type: 'valuation' },
        providerLeadId
      })
    },
    {
      enabled: calculatePayload !== undefined,
      staleTime: 0
    }
  )

  const { data: marketSalePrice, isFetching: isLoadingMarketSalePrice } = useQuery(
    ['providerLeadExpense', providerLeadId, calculatePayload, 'market'],
    () => {
      return fetchProviderLeadExpenseCalculate({
        payload: { ...calculatePayload, calculation_type: 'market' },
        providerLeadId
      })
    },
    {
      enabled: calculatePayload !== undefined,
      staleTime: 0
    }
  )

  const removeSnapshot = useMutation(snapshotId => deleteSnapshot({ snapshotId }), defaultCallback)

  return {
    providerLead,
    isLoading,
    snapshotId,
    saveProviderLead: (payload: any) => saveProviderLead.mutateAsync(payload),
    isSavingProviderLead: saveProviderLead.isLoading,
    saveSnapshot: (payload: any) => saveSnapshot.mutateAsync(payload),
    updateSnapshot: (payload: any) => updateSnapshot.mutateAsync(payload),
    isUpdatingSnapshot: updateSnapshot.isLoading,
    calculateExpense,
    setCalculateExpense: (payload: any) => {
      setCalculatePayload(payload)
    },
    isCalculatingExpense,
    marketSalePrice,
    isLoadingMarketSalePrice,
    removeSnapshot: (id: any) => removeSnapshot.mutateAsync(id),
    isSavingSnapshot: saveSnapshot.isLoading,
    isRemovingSnapshot: removeSnapshot.isLoading
  }
}
