const getApiRoot = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'https://hapi.homelight.com/api'
    case 'staging':
      switch (process.env.REACT_APP_STAGING_ENV) {
        case 'staging':
          return 'https://staging.hapi.homelight.com/api'
        case 'demo':
          return 'https://demo-api-staging.homelight.com/api'
        case 'flagstaff':
          return 'https://flagstaff-api-staging.homelight.com/api'
        case 'release':
          return 'https://staging.hapi.homelight.com/api'
        case 'snowbird':
          return 'https://snowbird-api-staging.homelight.com/api'
        case 'tahoe':
          return 'https://tahoe-api-staging.homelight.com/api'
        default: 
          return 'https://staging.hapi.homelight.com/api'
      }
    case 'development':
      return 'http://localhost:3001/api'
    case 'test': {
      return 'http://example.com/api'
    }
    default:
      return 'http://undefined.com/api'
  }
}

const relativeUrls = {
  providerLeads: '/lead-data-service/provider-leads',
  snapshots: '/lead-data-service/provider-leads/economic-model-snapshots',
  currentUser: '/user-auth-service/users/current',
  userAuth: '/user-auth-service/user-login'
}

type getUrlProps = {
  id?: string
  searchParams?: any
  action?: string
}

const queryStringify = (obj, prefix = undefined) => {
  const pairs = []
  for (let key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue
    }

    const value = obj[key]
    const enkey = encodeURIComponent(key)
    let pair = ''
    if (typeof value === 'object') {
      pair = queryStringify(value, prefix ? prefix + '[]' : enkey)
    } else {
      pair = (prefix ? prefix + '[' + enkey + ']' : enkey) + '=' + encodeURIComponent(value)
    }
    pairs.push(pair)
  }
  return pairs.join('&')
}

const getUrl = (relativeUrl: string, { id, searchParams, action }: getUrlProps = {}) => {
  const url = new URL(
    `${getApiRoot()}${relativeUrl}${id ? `/${id}` : ''}${action ? `/${action}` : ''}${
      searchParams ? '?' + queryStringify(searchParams) : ''
    }`
  )
  return url.href
}

const getUrlGenerator = (url: keyof typeof relativeUrls) => (params?: getUrlProps) =>
  getUrl(relativeUrls[url], params)

export const getProviderLeadsUrl = getUrlGenerator('providerLeads')
export const getSnapshotsUrl = getUrlGenerator('snapshots')
export const getCurrentUserUrl = getUrlGenerator('currentUser')
export const getUserAuthUrl = getUrlGenerator('userAuth')
