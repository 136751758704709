import { TradeInLeadResponse } from '@types'
import { snakeCase } from 'utils'

export type TradeInProvidable = {
  departing_property_expected_dom: number
  departing_property_external_loan_payoff: number
  departing_property_guaranteed_price: number
  departing_property_listing_prep_fee: number
  departing_property_ownership_expenses: number
  departing_property_repair_costs: number
  departing_property_solar_loan_balance: number
  departing_property_transaction_costs: number
  departing_property_valuation: number
  dp_actual_loan_payoff: number
  dp_buyers_agent_commission_rate: number
  dp_estimated_sale_price: number
  dp_gp_percentage_of_valuation: number
  dp_property_repair_holdback_rate: number
  dp_heloc_balance: number
  dp_loan_to_value_ratio: number
  dp_local_transfer_tax_rate: number
  dp_local_transfer_tax_split_rate: number
  dp_max_downpayment_equity: number
  dp_other_liens_balance: number
  dp_second_mortgage_balance: number
  dp_sellers_agent_commission_rate: number
  dp_solar_lease_payoff: number
  dp_target_annual_property_tax_rate: number
  dp_target_client_closing_costs: number
  dp_target_client_closing_costs_rate: number
  dp_target_client_total_closing_costs: number
  dp_target_monthly_hoa_fees: number
  dp_target_monthly_landscaping: number
  dp_target_monthly_property_insurance: number
  dp_target_monthly_utilities: number
  dp_target_monthly_water: number
  dp_target_new_home_purchase_equity: number
  dp_target_total_costs: number
  dp_first_position_payoffs: number
  dp_non_first_position_balances: number
  dp_target_trade_in_fee: number
  dp_target_trade_in_fee_rate: number
  dp_upfront_proceeds_to_client: number
  loan_payoff_value: number
}

const providableFields = [
  'departingPropertyExpectedDom',
  'departingPropertyExternalLoanPayoff',
  'departingPropertyGuaranteedPrice',
  'departingPropertyListingPrepFee',
  'departingPropertyOwnershipExpenses',
  'departingPropertyRepairCosts',
  'departingPropertySolarLoanBalance',
  'departingPropertyTransactionCosts',
  'departingPropertyValuation',
  'departingPropertyValuation',
  'dpActualLoanPayoff',
  'dpBuyersAgentCommissionRate',
  'dpEstimatedSalePrice',
  'dpFirstPositionPayoffs',
  'dpGpPercentageOfValuation',
  'dpHelocBalance',
  'dpLoanToValueRatio',
  'dpLocalTransferTaxRate',
  'dpLocalTransferTaxSplitRate',
  'dpMaxDownpaymentEquity',
  'dpOtherLiensBalance',
  'dpNonFirstPositionBalances',
  'dpSecondMortgageBalance',
  'dpSellersAgentCommissionRate',
  'dpSolarLeasePayoff',
  'dpTargetAnnualPropertyTaxRate',
  'dpTargetClientClosingCosts',
  'dpTargetClientClosingCostsRate',
  'dpTargetMonthlyHoaFees',
  'dpTargetMonthlyLandscaping',
  'dpTargetMonthlyPropertyInsurance',
  'dpTargetMonthlyUtilities',
  'dpTargetMonthlyWater',
  'dpTargetNewHomePurchaseEquity',
  'dpTargetTotalCosts',
  'dpTargetTradeInFee',
  'dpTargetTradeInFeeRate',
  'dpPropertyRepairHoldbackRate',
  'dpUpfrontProceedsToClient',
  'loanPayoffValue'
]

export class TradeInLead {
  id?: string
  createdAt?: string
  updatedAt?: string
  constructor(tradeInLead: TradeInLeadResponse) {
    Object.assign(this, tradeInLead)
  }

  getProvidable = (): TradeInProvidable =>
    Object.fromEntries(
      Object.entries(this)
        .filter(([key, _]) => !['getProvidable', 'id', 'createdAt', 'updatedAt'].includes(key))
        .filter(([key, _]) => providableFields.includes(key))
        .map(([key, val]) => [[snakeCase(key)], val && val !== 'undefined' ? parseFloat(val) : 0])
    )
}
